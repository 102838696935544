import React from "react"
import config from "../../utils/siteConfig"
import { graphql, PageProps, useStaticQuery } from "gatsby"

import { theme } from "../../components/Theme"
import HeroTopSection from "../../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../../components/dynamic-sections/RibbonSection"
import BlogCardsSection from "../../components/dynamic-sections/BlogCardsSection"
import { PaginationContext } from "../../utils/pagination"
import BlogMeta from "../../components/BlogMeta"
import LayoutBlog from "../../components/LayoutBlog"

/** color theme */
const colorPalette = theme.colorPalettes.blue

const BlogPage: React.FC<PageProps<Queries.GhostPostsPageQuery, PaginationContext>> = ({
  data,
  pageContext,
  location,
}) => {
  const posts = data.allGhostPost.nodes
  const settings = data.allGhostSettings.edges[0].node

  const title = "Blog | Norhart"
  const description =
    "The Norhart Blog will expand your knowledge in construction, technology, property management, apartments, customer experience, and more!"

  return (
    <LayoutBlog
      keywords="norhart, blog, property management, construction, lean, recruiting"
      imageTwitter={`${config.siteUrl}/blog/norhart-blog-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteUrl}/blog/norhart-blog-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="The Norhart Blog"
        subtitle="News, Design, CX, Culture, Travel, Tips & More!"
        imageTitle="Norhart Blog"
        image="/blog/norhart-blog-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Level Up Your Knowledge"
        tagLine="Check Back Often For New Posts & News"
        colorPalette={colorPalette}
      />

      <BlogMeta title={title} description={description} type="WebSite" settings={settings} location={location} />

      <BlogCardsSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        posts={posts}
        pageContext={pageContext}
      />
    </LayoutBlog>
  )
}

/** export */
/** export */
export default BlogPage

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostsPage($limit: Int!, $skip: Int!) {
    allGhostPost(sort: { order: DESC, fields: [published_at] }, limit: $limit, skip: $skip) {
      nodes {
        ...GhostPostFields
      }
    }

    allGhostSettings {
      edges {
        node {
          ...GhostSettingsFields
        }
      }
    }
  }
`
